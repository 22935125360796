import * as $ from 'jquery';
import './PassiveListeners';

import {
	bootMobileFilterTrigger,
	bootToTopBtn,
	bootAnimateNumbers,
	toNextScreenScroll,
	bootTrigger,
	bootVisit,
	bootLightbox,
	bootCopyCode,
	bootShowMoreBtn,
	bootReadMoreBtn,
	bootReadMoreCommentBtn,
	bootClosePopupBtn,
	bootRangeToolTip,
	bootSticky,
	bootTOCList,
	bootShowLessBtn,
	bootCasinoSelectPopup,
	bootHideCasinoSelectPopup,
	bootCloseCasinoSelectPopup,
	bootSetVisitedProducts,
	bootZoomImage,
} from "./common";
import './CommentsFilter';
import bootCookie from './cookies';
import {bootAddToCard, bootRemoveFromCart} from './Card';
import './FormHandlers';
import './BonusVoting';
import './CasinoCountriesTab';
import './CasinosLoadMore';
import './LoadMoreComments';
import './PaginateComments';
import './CountrySelect';
import {bootCartFormHandler} from './handlers/CartFormHandler';
import {bootCheckoutFormHandler} from './handlers/CheckoutFormHandler';
import {handleRating} from "./handlers/LoadCommentsHandler";
import {SendCommentHandler} from "./handlers/LoadCommentsHandler";
import {Gallery} from "./Gallery";

bootZoomImage();
bootCartFormHandler();
bootCheckoutFormHandler();
bootMobileFilterTrigger();
bootToTopBtn();
bootAnimateNumbers();
bootAddToCard();
bootRemoveFromCart();
toNextScreenScroll();
bootLightbox();
bootCopyCode();
bootTrigger();
bootVisit();
bootShowMoreBtn();
bootReadMoreBtn();
bootReadMoreCommentBtn();
bootClosePopupBtn();
bootRangeToolTip();
bootSticky();
bootTOCList();
bootShowLessBtn();
bootCookie();
bootCasinoSelectPopup();
bootHideCasinoSelectPopup();
bootCloseCasinoSelectPopup();
handleRating();
SendCommentHandler();
bootSetVisitedProducts();
Gallery();
$(document).ready(function(){
    "use strict";
});
