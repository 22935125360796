/**
 * To top Button
 */
import bootSetCookie from "./SetCookies";

export const bootSetVisitedProducts = () =>{
	/**
	 * Recent viewed products on cookies
	 */
	if( $('.single-product').length ){
		const prodId = $('.product-identifier-js').data('id');
		bootSetCookie('recentproduct_'+prodId,'viewed',3);
	}
}
export const bootToTopBtn = () => {
	$(".to-top-js").on("click", function () {
		$('html, body').animate({scrollTop: 0}, 1000);
	});
	/*Scroll top*/
	$(window).scroll(function(){
		if ($(this).scrollTop() > 100) {
			$(".to-top-js").removeClass('hidden');
		}
		else {
			$(".to-top-js").addClass('hidden');
		}
	});
}

export const bootMobileFilterTrigger = () =>{
	$('.js-mobile-filter-trigger').on('click',function(){
		$('.products-filter').toggle(500);
		$(this).toggleClass("opened");
	});
}

export const bootZoomImage = () => {
	$('.zoom-js').on('click', function () {
		const imgSrc = $(this).siblings('img').attr('src'); // Get thumbnail image source
		$('html,body').addClass("shadow");
		// Create the img element dynamically
		const img = $('<img>', {
			class: 'modal-content max-h-full max-w-full',
			src: imgSrc
		});

		// Display the modal and insert the dynamically created image
		const modal = $('#imageModal');
		modal.removeClass('hidden'); // Show the modal

		// Clear any previously existing image and add the new image
		modal.find('.modal-content').remove(); // Remove any existing image
		modal.append(img); // Append the new image

		// Close the modal when clicking the close button
		$('.close-modal').on('click', function () {
			$('html,body').removeClass("shadow");
			modal.addClass('hidden'); // Hide the modal
			img.remove(); // Remove the image to reset modal state
		});
	});
};

export const bootAnimateNumbers = () => {
	const $animateNumber = $(".animate-number-js");
	let animationTriggered = false; // Flag to track if animation has been triggered

	const animateNumber = function(t, e) {
		setTimeout(function() {
			$($animateNumber[t]).text(e);
		}, 100 + 20 * e);
	};

	if ($animateNumber.length) {
		$(window).scroll(function() {
			// Check if animation has already been triggered
			if (animationTriggered) return;

			const sectionOffset = $animateNumber.offset().top;
			if ($(window).scrollTop() + $(window).height() > sectionOffset + 100) {
				for (let y = 0; y < $animateNumber.length; y++) {
					const rating = parseInt($($animateNumber[y]).data("rating"));
					for (let i = 0; i < rating + 1; i++) {
						animateNumber(y, i);
					}
				}
				// Remove the class and prevent animation from triggering again
				$($animateNumber).removeClass("animate-number-js");
				animationTriggered = true; // Set flag to true to prevent future triggers
			}
		});
	}
};




/**
 * FAQ Component
 */

export const bootFAQComponent = () => {
	$('.js-faq-question').on('click',function(){
		const $faqItem = $('.js-faq-block');
		const $answer = $('.js-faq-answer');
		if( $(this).parent().hasClass('active') ){
			$faqItem.removeClass('active');
			$(this).parent().find('.js-faq-answer').slideUp(300);
		}else{
			$faqItem.removeClass('active');
			$(this).parent().addClass('active');
			$answer.slideUp(300);
			$(this).parent().find('.js-faq-answer').slideDown(300);
		}
	});
}

export const bootCasinoSelectPopup = () => {
	const $countriesBarPopup = $('.countries-bar-js');
	const $popup = $('.country-switcher-popup-js');
	$countriesBarPopup.on('click',function(){
		if( $popup.hasClass('hidden') ){
			$popup.removeClass('hidden');
		}else{
			$popup.addClass('hidden');
		}
	});
}
export const bootHideCasinoSelectPopup = () => {
	$(document).on('click', function(event) {
		const $popup = $('.country-switcher-popup-js');
		if (!$(event.target).closest('.country-switcher-popup-js').length && !$(event.target).closest('.countries-bar-js').length) {
			$popup.addClass('hidden');
		}
	});
}

export const bootCloseCasinoSelectPopup = () =>{
	const $close = $('.close-switcher-popup-js');
	$close.on('click',function (){
		$('.country-switcher-popup-js').addClass('hidden');
	})
}

export const bootLightbox = () =>{
	const $lightboxContainer = $('#lightbox');
	const $lightboxImg = $('#lightbox-img');
	$('.lightbox-thumbnail-js').on('click',function(){
		$lightboxImg.attr('src', $(this).data('src'));
		$lightboxContainer.removeClass('hidden');
	});
	$('.lightbox-img-js').on('click',function(){
		$lightboxImg.attr('src', $(this).attr('src'));
		$lightboxContainer.removeClass('hidden');
	});
	$('.the-content-js').on('click', 'img', function () {
		$lightboxImg.attr('src',  $(this).attr('src'));
		$lightboxContainer.removeClass('hidden');
	});
	$('#lightbox span').on('click',function () {
		$('#lightbox').addClass('hidden');
	});

}

export const bootVisit = () => {
	$('.visit-js').on('click',function(){
		const url = $(this).data('visit');
		if (url) {
			window.open(url, '_blank');
		}
	});
}


export const bootTrigger = () => {
	/*Menu*/
	$(".trigger").on('click',function () {
		$('.main-menu').slideToggle();
		$(this).toggleClass("opened");
		$('html,body').toggleClass("shadow");
	});
}
export const toNextScreenScroll = () =>{
	$(".btn-scroll-down-js").click(function(e){
		const t=$("#products_list").offset();
		$("html, body").animate({scrollTop:t.top - 150},500)});
}


export const bootCopyCode = () =>{
	$('.bonus-code-js').on('click',function() {
		const textToCopy = $(this).get(0);
		const range = document.createRange();
		range.selectNode(textToCopy);

		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			document.execCommand('copy');
		} catch (err) {
			console.error(err);
		}

		window.getSelection().removeAllRanges();
	});
}

export const bootShowMoreBtn = () => {
	$('body').on('click','.js-show-more-items',function(){
		const $parentBlock = $(this).parent();
		const $this = $(this);
		const itemType = $this.data('item-type');
		$parentBlock.find($(`.${itemType}-item`)).slideDown();
		$parentBlock.find($(`.${itemType}-separator`)).show();
		$parentBlock.removeClass('last-solarized');
		$this.hide();
		$('.js-show-less').removeClass('hidden');
	});
}
export const bootShowLessBtn = () => {
	$('.js-show-less').on('click',function(){
		const $items =$('.toc-item');
		const $this = $(this);
		$items.hide();
		$items.slice(0, 4).show();
		$('.js-show-more-items').show();
		$this.addClass('hidden');
	});
}

export const bootShowMorePaymentsBtn = () => {
	$('.js-show-more-payments').on('click',function(){
		const popupContent = $(this).closest('.js-wrapper').find('.popup-content');
		popupContent.empty();
		$(this).siblings('.payment-item').each(function () {
			const clonedItem = $(this).clone();
			clonedItem.show();
			popupContent.append(clonedItem);
		});
		popupContent.closest('.popup').show();
	});
}
export const bootClosePopupBtn = () => {
	$('.close-popup-js').on('click',function () {
		$(this).closest('.popup').hide();
	});
}

export const bootReadMoreBtn = () => {
	$('body').on('click','.expand-text-button-js', function() {
		$(this).parent().find('.short-text-js').removeClass('solarized');
		$(this).hide();
	});
}

//transform_to_expand_comment
export const bootReadMoreCommentBtn = () => {
	$('body').on('click','.expand-comment-button-js', function() {
		$(this).parent().find('.short-comment-js').addClass('hidden');
		$(this).parent().find('.full-comment-js').removeClass('hidden');
		$(this).hide();
	});
}

export const bootRangeToolTip = () => {
	$('#user_rating').on('input', function(){
		const $tooltip = $('#range_tooltip');
		const range = $(this);
		const rating = range.val();
		const left = rating * 10;
		const diff = rating * 2;
		$tooltip.css({'left':'calc('+left+'% - '+ diff + 'px)','display':'block'});
		$tooltip.text(rating);
	});
}

export const bootSticky = () => {
	const $sticky = $('.js-sticky');
	if( $sticky && $('.single-casino').length !== 0 ){
		const blockHeight = $sticky.innerHeight();
		const stickyTop = $sticky.offset().top;
		const stopPoint = $('#toc_customer_review_form').offset().top - $(window).height();
		$(window).scroll(function () {
			const windowScrollTop = $(window).scrollTop();
			const $mobileSticky = $('#mobile_sticky');
			if( isMobileDevice() && $mobileSticky.length !== 0 ){
				 if( stickyTop + blockHeight < windowScrollTop ){
					 $mobileSticky.removeClass('hidden');
				}else{
					 $mobileSticky.addClass('hidden');
				 }
				if ( stopPoint < windowScrollTop ) {
					$mobileSticky.addClass('hidden');
				}
			}
		});

		$('.tray-js').on('click', function () {
			const $mobileSticky = $('#mobile_sticky');
			const mobileStickyWidth = $mobileSticky.width();
			const $trayExplode = $('.tray-explode-js');
			const windowWidth = window.innerWidth;
			const hiddenSize = (0.5 * windowWidth) -  (mobileStickyWidth/6);
			const transformValue = 'translateX(' + hiddenSize + 'px)';
			$mobileSticky.css('transform', transformValue);
			$trayExplode.removeClass('hidden');
		});

		$('.tray-explode-js').on('click',function(){
			const $mobileSticky = $('#mobile_sticky');
			$(this).addClass('hidden');
			$mobileSticky.css('transform','translateX(-50%)');
		});

		let isBlockCloned = false;
		$('.expand-sticky-js').on('click', function () {
			if( !isBlockCloned ){
				const $sticky = $('.js-sticky');
				const $clonedSticky = $sticky.clone();
				$clonedSticky.addClass('js-sticky-clone');
				const closeButton = $('<button>').attr('id', 'closeButton').text('×');
				const popupContainer = $('<div>').attr('id', 'popupContainer').append($clonedSticky);
				popupContainer.append(closeButton);
				$('body').append(popupContainer);
				isBlockCloned = true;
				closeButton.click(function() {
					popupContainer.hide();
				});
			}
			$('#popupContainer').css('display','flex');
		});


	}

}
function isMobileDevice() {
	return window.innerWidth < 1199;
}

export const bootTOCList = () => {
	$('.js-toc_table_list_heading ul li a, .js-toc-a').on("click", function (e) {
		e.preventDefault();
		const id = $(this).attr("href");
		const top = $(id).offset().top - 80;
		$('html, body').animate({scrollTop: top}, 500);
	});
}
